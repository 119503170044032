/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { Component } from 'react';
import { CheckIcon } from '@heroicons/react/outline'
import { StaticImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import { constants } from '../../constants';

export default class Features extends Component
{
  public render(): React.ReactNode
  {
    return (
      <div className="py-24 md:py-36">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h1 className="mt-2 text-3xl text-slate-800 leading-8 font-extrabold tracking-tight sm:text-4xl">
            <Trans>Become... Better Faster Stronger</Trans>
          </h1>
          <p className="mt-4 max-w-2xl text-xl lg:mx-auto">
            <Trans>Power-based indoor training has proven to be very effective in increasing your cycling performance.</Trans>
          </p>
        </div>

        <div className="mt-12 md:mt-24 mb-8 md:grid md:grid-cols-2 md:gap-x-16 md:gap-y-10">
          <div>
            <StaticImage className="relative rounded-lg shadow-lg mb-8 md:mb-0" src="../../images/mockups/mockup-workout-list.jpg" alt="A note-taking app" />
          </div>
          <div className="relative my-auto">

              <h2 className="text-2xl font-bold mb-5 text-slate-800"><Trans>Train indoor with structured workouts</Trans></h2>
              <p className="text-md"><Trans>(1) Pair your trainer. (2) Enter the kind of training you want to do as well as the duration. (3) Select one of the suggested workouts.</Trans></p>

              <div className="relative space-y-5 mt-8">
                <div>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-md leading-6"><Trans>50+ Tailor-made structured workouts.</Trans></p>
                </div>
                <div>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-md leading-6 "><Trans>All training zones: <b>Active Recovery, Endurance, Tempo, Sweet Spot, Lactate Threshold, VO2Max, Anaerobic, Neuromuscular.</b></Trans></p>
                </div>
                <div>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-md leading-6">ANT+ & Bluetooth Compatibility.</p>
                </div>
              </div>
          </div>

        </div>


        <div className="mt-12 md:mt-48 md:grid md:grid-cols-2 md:gap-x-16 md:gap-y-10">
          <div className="relative my-auto">

              <h2 className="text-2xl font-bold mb-5 text-slate-800"><Trans>Stay motivated by watching your favorite series while training</Trans></h2>
              <p className="text-md"><Trans>Sometimes it can be difficult to motivate yourself to train indoor. That's why we offer you the possibility to watch movies and series in full screen with your training data in overlay.</Trans></p>

              <div className="relative space-y-5 mt-8">
                <div>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-md leading-6"><Trans>Watch <b>Netflix</b>, <b>Youtube</b>, or any video file.</Trans></p>
                </div>
                <div>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-md leading-6 "><Trans>Enjoy it in full screen with your training data in overlay.</Trans></p>
                </div>
              </div>

          </div>
          <div>
            <StaticImage className="relative rounded-lg shadow-lg mt-8 md:mt-0" src="../../images/mockups/mockup-minimized-mode.jpg" alt="A note-taking app" />
          </div>
        </div>
      </div>
    </div>
    );
  }
}
