/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/sections/hero';
import Features from '../components/sections/features';
import Integrations from '../components/sections/integrations';
import CTA from '../components/sections/cta';
import { SEO } from '../components/seo';
import { useTranslation } from 'react-i18next';

const IndexPage = () =>
{
  const { t } = useTranslation();

  return (
    <>
      <SEO
        title={t('Wattrainer - Structured Workout for Cycling')}
        description={t('Become a stronger cyclist by training with power-based structured workouts. Wattrainer offer 50+ workouts compatible with indoor trainers.')}
      ></SEO>
      <Layout>
        <Hero />
        <Features />
        <Integrations />
        <CTA />
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
