/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { StaticImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { constants } from '../../constants';

export default class Hero extends Component
{
  public render(): React.ReactNode
  {
    return (
      <StaticQuery
        query={graphql`
          query {
            desktop: file(relativePath: { eq: "backgrounds/hero-background.jpg" }) {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => {
          return (
          <div className="relative overflow-hidden">
            <div className="absolute inset-y-0 h-full w-full bg-slate-900 clip-path-curved" aria-hidden="true">
            <BackgroundImage
              className="h-full opacity-5"
              fluid={data.desktop.childImageSharp.fluid}
              preserveStackingContext={true}
            />

            </div>

            <div className="relative py-10">

              <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
                <div className="text-center">
                  <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-5xl">
                    <span className="block"><Trans>Smart Training for <span className="text-primary">Smart Cyclist</span></Trans></span>
                  </h1>
                  <p className="mt-3 max-w-md mx-auto text-base text-slate-400 sm:text-lg md:mt-5 md:text-2xl md:max-w-3xl">
                  <Trans>Become a stronger cyclist with structured workouts. Motivate yourself by watching your favorite series while training.</Trans>
                  </p>
                </div>
              </div>
              <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8 p-2">
                  <div className="rounded-full shadow">
                    <a
                      href={constants.links.signUp}
                      target="_blank"
                      className="md:w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold rounded-full text-white bg-primary hover:bg-primary-600 md:py-3 md:text-xl md:px-10"
                    >
                      <Trans>➜ Get started, it's free</Trans>
                    </a>
                  </div>
                </div>
            </div>

            <div className="relative">
              <div className="max-w-6xl mx-auto pb-8 px-4 sm:px-24 text-center">
                <StaticImage className="relative rounded-lg shadow-lg" src="../../images/mockups/mockup-workout.jpg" alt="Structured workout for cyclist in Wattrainer" />
              </div>
            </div>
          </div>
          );
        }
      }
      />
    );
  }
}

