/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';
import { Trans } from 'react-i18next';
import { constants } from '../../constants';
import { DownloadIcon } from '@heroicons/react/solid';

const CTA = () => (
  <div className="bg-white">
      <div className="max-w-7xl mx-auto text-center pt-24 px-4 sm:px-6 lg:pt-32 lg:px-8">
        <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight">
          <span className="block"><Trans>Ready to train with Wattrainer?</Trans></span>
          <span className="block"><Trans>Get started for free</Trans></span>
        </h2>
        <div className="mt-8 flex justify-center">
        <a
                href={constants.links.signUp}
                target="_blank"
                className="inline-flex no-underline items-center cursor-pointer text-base font-bold justify-center px-5 py-3 rounded-md text-white bg-primary hover:bg-primary-600"
              >
                <DownloadIcon className='mr-2 -ml-1 w-4 h-4'></DownloadIcon>
                <Trans>Get Wattrainer</Trans>
              </a>


        </div>
      </div>
    </div>
)

export default CTA;