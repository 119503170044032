/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { StaticImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { Component } from 'react';

export default class Integrations extends Component
{
  public render(): React.ReactNode
  {
    return (
      <div className="bg-[#fee9db] w-full md:w-3/4 lg:max-w-7xl md:rounded-xl py-20 sm:py-24 mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="mt-2 text-3xl text-slate-800 leading-8 font-extrabold tracking-tight sm:text-4xl">
            <Trans>Integrated with Strava</Trans>
          </h1>
          <p className="mt-4 max-w-2xl text-xl lg:mx-auto">
            <Trans>If you enable the Strava integration, your training sessions will be automatically uploaded to Strava.</Trans>
          </p>

          <div className="relative mt-10">
            <StaticImage className="max-w-[200px]" src="../../images/integrations/strava.png" alt="Strava integration" />
          </div>
        </div>
      </div>
    );
  }
}